<template>
  <div class="container-md">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="bg-white border border-primary text-dark my-4">
          <!-- nav -->
          <ul class="d-flex flex-row p-0 m-0">
            <li
              class="w-50"
              :class="[$style[`tab`],
                       {[$style[`active`]]: currentTab === 'ComponentLogin'},
                       {[$style[`border-end-active`]]: currentTab === 'ComponentLogin'}]"
              role="button"
              @click="setCurrentTab('ComponentLogin')"
            >
              登入
            </li>
            <li
              class="w-50"
              :class="[$style[`tab`],
                       {[$style[`active`]]: currentTab === 'ComponentSignup'},
                       {[$style[`border-start-active`]]: currentTab === 'ComponentSignup'}]"
              role="button"
              @click="setCurrentTab('ComponentSignup')"
            >
              註冊
            </li>
          </ul>
          <!-- <Login /> -->
          <div class="tab-content py-4 px-4">
            <!-- login form -->
            <keep-alive>
              <component :is="currentTab" />
            </keep-alive>
            <!-- register form -->
            <!-- <ComponentSignup /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ComponentLogin from '@/components/front/validate/Login.vue'
import ComponentSignup from '@/components/front/validate/Signup.vue'
import { ref, provide } from 'vue'
export default {
  name: 'Login',
  components: {
    ComponentLogin,
    ComponentSignup
  },
  setup () {
    const account = ref('')
    const currentTab = ref('ComponentLogin')
    const setCurrentTab = (tab) => {
      currentTab.value = tab
    }

    provide('account', account)

    return {
      currentTab, setCurrentTab
    }
  }
}
</script>
<style lang="scss" module>
.tab {
  background-color: rgba($primary, 0.1);
  border: solid 1px transparent;
  border-bottom-color: rgba($primary, 0.5);
  color: $dark;
  list-style: none;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: border-color 0.3s, background-color 0.3s;
}

.tab.active {
  background-color: $white;
  border-bottom-color: transparent;
}

.tab.active.border-end-active {
  border-right-color: rgba($primary, 0.5);
}

.tab.active.border-start-active {
  border-left-color: rgba($primary, 0.5);
}
</style>
