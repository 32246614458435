<template>
  <div>
    <!-- 帳號型態 -->
    <!-- <label class="d-block">帳號型態<span class="text-danger">*</span></label> -->
    <!-- <select class="form-control mb-3" name="typeID" v-model="accountType">
                                        <option value=null disabled>選擇帳號型態</option>
                                        <option value="0">Email</option>
                                        <option value="1">手機號碼</option>
    <option value="2">身分證號</option>
                                    </select> -->
    <div class="row">
      <div
        v-for="field in fieldListLogin"
        :key="field"
        :class="styleMapLogin?.[field] || ''"
      >
        <InputPassword
          v-if="field.toLowerCase().includes('password')"
          :id="field"
          v-model:input="state[field]"
          :type="field"
          :required="true"
          :label="labelMap[field].label"
          :placeholder="labelMap[field].placeholder"
          :err-message="v$[field]?.$errors[0]?.$message || ''"
          @focus="v$[field].$touch"
        />
        <Input
          v-else
          :id="field"
          v-model:input="state[field]"
          :required="true"
          :autocomplete="'on'"
          :label="labelMap[field].label"
          :placeholder="labelMap[field].placeholder"
          :err-message="v$[field]?.$errors[0]?.$message || ''"
          @focus="v$[field].$touch"
          @enter="readMemberID"
        />
      </div>
      <!-- 驗證碼 -->
      <div
        class="col-6 d-flex flex-column align-items-center justify-content-between"
      >
        <div
          :class="$style[`height-100px`]"
        >
          <img
            v-if="kaptcha"
            :src="kaptcha"
            role="button"
            class="img-contain"
            :class="$style[`img-contain`]"
            title="看不清楚換一張"
            @click="readKaptcha"
          >
        </div>
        <small class="text-xs">(點選圖片重新取得一組驗證碼)</small>
      </div>
    </div>
    <!-- :disabled="account=='' || password=='' || verification === ''" -->
    <button
      ref="loginBtn"
      class="btn bg-primary rounded-0 text-white w-100 mb-3"
      type="button"
      @click="readMemberID"
    >
      開始購物
    </button>
    <div class="w-100 text-center mb-1">
      <span
        class="pointer small"
        role="button"
        @click="setShowResetPasswordModal(true)"
      >忘記密碼？</span>
    </div>
    <div class="w-100 text-center">
      <span
        class="pointer small"
        role="button"
        @click="setShowRevalidationModal(true)"
      >沒收到驗證通知？</span>
    </div>

    <!-- 重設密碼 -->
    <teleport to="#modalWrap">
      <div
        v-show="showResetPasswordModal"
      >
        <div
          :class="$style[`shadow`]"
          @click="setShowResetPasswordModal(false)"
        />
        <div
          id="forgotPwdModal"
          class="modal-lg position-fixed top-50 start-50 translate-middle"
          :class="$style[`modal`]"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content pop-up bg-white rounded-0">
              <div class="modal-body text-center p-4">
                <h6 class="text-primary mb-3">
                  請選擇帳號型態並輸入帳號，<br class="d-inline d-md-none">我們將發送重設密碼通知。
                </h6>
                <div class="position-relative mb-4">
                  <div
                    class="d-flex flex-column"
                  >
                    <select
                      v-model="state.accountType"
                      class="form-select"
                      :class="{'border-danger': v$.accountType?.$error}"
                      name="typeID"
                      required
                      @focus="v$.accountType.$touch"
                    >
                      <option
                        value="null"
                        disabled
                      >
                        選擇帳號型態
                      </option>
                      <option value="0">
                        Email
                      </option>
                      <option value="1">
                        手機號碼
                      </option>
                      <!--                                 <option value="2">身分證號</option> -->
                    </select>
                  </div>
                  <small
                    class="text-danger position-absolute end-0"
                    :class="[$style[`error-text`],{[$style[`show`]]: v$.accountType?.$errors[0]?.$message !==''}]"
                  >{{ v$.accountType?.$errors[0]?.$message || '' }}</small>
                </div>

                <Input
                  id="resetPwdAccount"
                  v-model:input="state.resetPwdAccount"
                  type="text"
                  :required="true"
                  :autocomplete="'off'"
                  :label="labelMap.resetPwdAccount.label"
                  :placeholder="labelMap.resetPwdAccount.placeholder"
                  :err-message="v$.resetPwdAccount?.$errors[0]?.$message || ''"
                  @focus="v$.resetPwdAccount.$touch"
                  @enter="createResetPasswordRequest"
                />
              </div>
              <div class="modal-footer justify-content-center p-0 mb-3 border-0">
                <a
                  class="submitBtn btn btn-primary text-white rounded-0 m-2 px-4"
                  @click="createResetPasswordRequest"
                >重設密碼</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 重新發送驗證通知 -->
      <div
        v-show="showRevalidationModal"
      >
        <div
          :class="$style[`shadow`]"
          @click="setShowRevalidationModal(false)"
        />
        <div
          id="forgotPwdModal"
          class="modal-lg position-fixed top-50 start-50 translate-middle"
          :class="$style[`modal`]"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content pop-up bg-white rounded-0">
              <div class="modal-body text-center p-4">
                <h6 class="text-primary mb-3">
                  請選擇帳號型態並輸入帳號，<br class="d-inline d-md-none">我們將重新發送驗證通知。
                </h6>

                <div class="position-relative mb-4">
                  <div
                    class="d-flex flex-column"
                  >
                    <select
                      v-model="state.accountType"
                      class="form-select"
                      :class="{'border-danger': v$.accountType?.$error}"
                      name="typeID"
                      required
                      @focus="v$.accountType.$touch"
                    >
                      <option
                        value="null"
                        disabled
                      >
                        選擇帳號型態
                      </option>
                      <option value="0">
                        Email
                      </option>
                      <option value="1">
                        手機號碼
                      </option>
                      <!--                                 <option value="2">身分證號</option> -->
                    </select>
                  </div>
                  <small
                    class="text-danger position-absolute end-0"
                    :class="[$style[`error-text`],{[$style[`show`]]: v$.accountType?.$errors[0]?.$message !==''}]"
                  >{{ v$.accountType?.$errors[0]?.$message || '' }}</small>
                </div>
                <Input
                  id="validateMail"
                  v-model:input="state.validateMail"
                  type="text"
                  :required="true"
                  :autocomplete="'off'"
                  :label="labelMap.resetPwdAccount.label"
                  :placeholder="labelMap.resetPwdAccount.placeholder"
                  :err-message="v$.validateMail?.$errors[0]?.$message || ''"
                  @focus="v$.validateMail.$touch"
                  @enter="createRevalidationRequest"
                />
              </div>
              <div class="modal-footer justify-content-center p-0 mb-3 border-0">
                <a
                  class="submitBtn btn btn-primary text-white rounded-0 m-2 px-4"
                  @click="createRevalidationRequest"
                >重新發送驗證通知</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
import Input from '@/components/Input.vue'
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, emailHelper, mobileHelper, helpers, or } from '@/hooks/useVuelidate.js'
import { useGtag } from 'vue-gtag-next'

import { ref, inject, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'ComponentLogin',
  components: {
    Input, InputPassword
  },
  async setup (props) {
    const { dispatch, commit, getters } = useStore()
    const { push } = useRouter()
    const { getErrorMessage } = useErrorMessage()
    const { event } = useGtag()

    const memberID = computed(() => getters['member/getMemberID'])

    // GA自訂事件-登入
    const gaLogin = () => {
      event('login', {
        event_category: 'methods',
        event_label: ''
      })
    }

    const kaptcha = ref('')
    const readKaptcha = async () => {
      kaptcha.value = ''

      try {
        const response = await dispatch('validate/readKaptcha', { payload: {} })
        if (response?.data) {
          const image = btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          )
          kaptcha.value = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`
        } else {
          kaptcha.value = ''
        }
        return kaptcha.value
      } catch (error) {
        kaptcha.value = ''
        return ''
      }
    }

    const account = inject('account')
    const setAlert = inject('setAlert')

    const showResetPasswordModal = ref(false)
    const showRevalidationModal = ref(false)

    const setShowResetPasswordModal = (show) => {
      showResetPasswordModal.value = show
      v$.value.$reset()
    }
    const setShowRevalidationModal = (show) => {
      showRevalidationModal.value = show
      v$.value.$reset()
    }

    const fieldListLogin = ['account', 'password', 'verification']

    const labelMap = {
      account: {
        label: 'Email或手機',
        placeholder: '請輸入Email或手機'
      },
      password: {
        label: '密碼',
        placeholder: '請輸入密碼'
      },
      verification: {
        label: '驗證碼',
        placeholder: '請輸入驗證碼'
      },
      resetPwdAccount: {
        label: '帳號',
        placeholder: '請輸入帳號'
      },
      validateMail: {
        label: '帳號',
        placeholder: '請輸入帳號'
      }
    }

    const styleMapLogin = {
      account: 'mb-4',
      password: 'mb-4',
      verification: 'col-6'
    }

    const state = reactive({
      account,
      password: '',
      verification: '',
      accountType: null,
      validateMail: '',
      resetPwdAccount: ''
    })

    const rules = computed(() => {
      const ruleMapLogin = {
        account: {
          requiredHelper,
          shouldBeChecked: helpers.withMessage(
            '格式錯誤',
            or(emailHelper, mobileHelper)
          )
        },
        password: { requiredHelper },
        verification: { requiredHelper },
        accountType: { },
        resetPwdAccount: { },
        validateMail: { }
      }

      const ruleMapResetPwd = {
        account: { },
        password: { },
        verification: { },
        accountType: { requiredHelper },
        resetPwdAccount: { requiredHelper },
        validateMail: { }
      }

      const ruleMapRevalidation = {
        account: { },
        password: { },
        verification: { },
        accountType: { requiredHelper },
        resetPwdAccount: { },
        validateMail: { requiredHelper }
      }

      if (showResetPasswordModal.value) {
        if (state.accountType === '0') {
          return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper, emailHelper } })
        } else if (state.accountType === '1') {
          return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper, mobileHelper } })
        } else {
          return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper } })
        }
        // return Object.assign({}, ruleMapResetPwd)
      } else if (showRevalidationModal.value) {
        if (state.accountType === '0') {
          return Object.assign({}, ruleMapRevalidation, { validateMail: { requiredHelper, emailHelper } })
        } else if (state.accountType === '1') {
          return Object.assign({}, ruleMapRevalidation, { validateMail: { requiredHelper, mobileHelper } })
        } else {
          return Object.assign({}, ruleMapRevalidation, { validateMail: { requiredHelper } })
        }
      } else {
        return ruleMapLogin
      }
    })

    const v$ = useVuelidate(rules, state)

    const readCartMount = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value }

        try {
          const response = await dispatch('cart/readCartMount', { payload })
          return response
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const readCart = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value, offset: 0, pageSize: 10 }

        try {
          const response = await dispatch('cart/readCart', { payload })
          if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const readMemberID = async () => {
      gaLogin()
      v$.value.$touch()

      const payload = {
        account: account.value,
        pwd: state.password,
        verification: state.verification
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch('member/readMemberID', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            state.verification = ''
            readKaptcha()

            // if is wrong password, clear password, focus password input
            if (response.data.errMsg.split(':')[0] === '404-4') {
              state.password = ''
              document.getElementById('password').focus()
            }
          } else {
            setAlert(true, true, '登入成功！')
            await readCartMount()
            await readCart()
            push('/')
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `登入失敗: ${error}`)
          state.verification = ''
          readKaptcha()
        }
      } else {
        state.verification = ''
        readKaptcha()
      }
    }

    const createResetPasswordRequest = async () => {
      v$.value.$touch()
      const payload = {
        resetPwdAccount: state.resetPwdAccount,
        accountType: state.accountType
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch('validate/createResetPasswordRequest', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            setShowResetPasswordModal(false)
            state.accountType = null
          } else {
            setAlert(true, true, '發送成功')
            setShowResetPasswordModal(false)
            state.accountType = null
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `發送失敗: ${error}`)
          setShowResetPasswordModal(false)
          state.accountType = null
        }
      }
    }

    const createRevalidationRequest = async () => {
      v$.value.$touch()

      const payload = {
        validateMail: state.validateMail,
        accountType: state.accountType
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch('validate/createRevalidationRequest', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            setShowRevalidationModal(false)
            state.accountType = null
          } else {
            setAlert(true, true, '發送成功')
            setShowRevalidationModal(false)
            state.accountType = null
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `發送失敗: ${error}`)
          setShowRevalidationModal(false)
          state.accountType = null
        }
      }
    }

    await readKaptcha()

    return {
      v$,
      fieldListLogin,
      account,
      styleMapLogin,
      state,
      kaptcha,
      labelMap,
      readKaptcha,
      readMemberID,
      showResetPasswordModal,
      showRevalidationModal,
      setShowResetPasswordModal,
      setShowRevalidationModal,
      createResetPasswordRequest,
      createRevalidationRequest,
      gaLogin
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}

.height-100px {
  height: 100px;
}

.img-contain {
  height: 100px;
  min-height: 100px;
  object-fit: contain;
  width: 100%;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

</style>
