<template>
  <div class="fade">
    <div
      v-for="field in fieldList"
      :key="field"
    >
      <InputPassword
        v-if="field.toLowerCase().includes('password')"
        :id="field"
        v-model:input="state[field]"
        class="mb-4"
        :type="field"
        :required="true"
        :label="labelMap[field].label"
        :placeholder="labelMap[field].placeholder"
        :err-message="v$[field]?.$errors[0]?.$message || ''"
        @focus="v$[field].$touch"
      />
      <Input
        v-else
        :id="field"
        v-model:input="state[field]"
        class="mb-4"
        :type="field"
        :required="true"
        :label="labelMap[field].label"
        :maxlength="inputMap[field]?.maxlength"
        :placeholder="labelMap[field].placeholder"
        :err-message="v$[field]?.$errors[0]?.$message || ''"
        @focus="v$[field].$touch"
      />
    </div>
    <button
      class="btn bg-primary rounded-0 text-white w-100 mb-3"
      type="button"
      @click="createMemberID"
    >
      立即加入
    </button>
  </div>
</template>
<script>
import Input from '@/components/Input.vue'
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, passwordHelper, mobileHelper, emailHelper, helpers, sameAs } from '@/hooks/useVuelidate.js'
import { ref, inject, reactive } from 'vue'
import { useStore } from 'vuex'
import { useErrorMessage } from '@/hooks/errorMessage.js'
import { useGtag } from 'vue-gtag-next'

export default {
  name: 'LoginSignup',
  components: {
    Input, InputPassword
  },
  setup (props) {
    const { dispatch } = useStore()
    const { getErrorMessage } = useErrorMessage()
    const { event } = useGtag()

    const email = ref('')
    const cell = ref('')
    const showName = ref('')
    const password = ref('')
    const checkPassword = ref('')

    const setAlert = inject('setAlert')

    const fieldList = ['email', 'cell', 'showName', 'password', 'checkPassword']

    const labelMap = {
      email: {
        label: 'Email',
        placeholder: '請輸入Email'
      },
      cell: {
        label: '手機號碼',
        placeholder: '請輸入手機號碼'
      },
      showName: {
        label: '名稱',
        placeholder: '請輸入名稱'
      },
      password: {
        label: '密碼',
        placeholder: '請輸入密碼'
      },
      checkPassword: {
        label: '再次輸入密碼',
        placeholder: '請再次輸入密碼'
      }
    }

    const inputMap = {
      email: {
      },
      cell: {
      },
      showName: {
        maxlength: 30
      },
      password: {
      },
      checkPassword: {
      }
    }

    const state = reactive({
      email,
      cell,
      showName,
      password,
      checkPassword
    })

    const rules = {
      email: { requiredHelper, emailHelper },
      cell: { requiredHelper, mobileHelper },
      showName: { requiredHelper },
      password: { requiredHelper, passwordHelper },
      checkPassword: { requiredHelper, passwordHelper, sameAsRef: helpers.withMessage('請確認兩次密碼是否一致', sameAs(password)) }
    }

    const v$ = useVuelidate(rules, state)

    // GA自訂事件-註冊
    const gaSignUp = () => {
      event('sign_up', {
        event_category: 'methods',
        event_label: ''
      })
    }

    const createMemberID = async () => {
      gaSignUp()
      v$.value.$touch()
      if (!v$.value.$error) {
        const payload = {
          email: email.value,
          cell: cell.value,
          showName: showName.value,
          pwd: password.value
        }

        try {
          const response = await dispatch('member/createMemberID', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, '註冊成功。驗證通知已發送，請至驗證通知確認啟用。')
          }

          return response
        } catch (error) {
          console.log(error)
          // console.log('get user info error: ' + api + ', ' + error)
          setAlert(true, false, `註冊失敗: ${error}`)
        // window.setTimeout(() => location.reload(), 2000)
        }
      }
    }

    return {
      createMemberID, v$, labelMap, fieldList, state, email, cell, showName, checkPassword, password, inputMap, gaSignUp
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}

</style>
